import Vue from 'vue';
import ContentResource, { ContextWithLocale } from './ContentResource';
import { CreatePage } from '../ContentResource/ContentResource.type';
import { Context } from '@nuxt/types';
export interface PageBuilderOptions extends CreatePage {
  serverTemplate: Vue.Component;
  prefetch?: (ct: Context) => Promise<object>;
}

interface IncomingMessageWithSession {
  session: { countryID: string };
}
export default class PageBuilder {
  options: PageBuilderOptions;
  dataQuery: { [key: string]: any } = {};
  generalErrorData: { [key: string]: any } = {};

  constructor({ asyncFunc = async () => false, ...options }: PageBuilderOptions) {
    this.options = { asyncFunc, ...options };
  }

  render(): Vue.Component {
    const { serverTemplate, prefetch = () => {}, asyncFunc = () => null, ...createPageOptions } = this.options;
    createPageOptions.queries = { ...createPageOptions.queries, QoalaOnlineError: [] };
    const contentResource = new ContentResource(createPageOptions);

    return Vue.extend({
      /**
       * Base page data
       */
      data() {
        return {
          content: createPageOptions.queries,
          countryCode: ''
        };
      },
      /**
       * Universal Fetch
       */
      async asyncData(context: ContextWithLocale) {
        const content = await contentResource.getContent(
          createPageOptions.components,
          context.app.i18n.locales.find(locale => locale.code.toLowerCase() === context.app.i18n.locale.toLowerCase())
            ?.iso,
          context.app.i18n
        );

        let countryCode = '';
        if (process.server) {
          countryCode = ((context.req as unknown) as IncomingMessageWithSession).session.countryID;       
          context.store.commit('core/setCountryCode', countryCode);
        } else {
          try {
            const country = context.store.getters['core/getCountryCode'];
            countryCode = country;
          } catch (e) {
            // TODO: handle sentry logger
            console.error("Error, couldn't fetch country code on browser navigation", e);
          }
        }
        const asyncData = await asyncFunc(context);
        const pageData = await prefetch(context);

        return {
          ...pageData,
          content,
          countryCode,
          asyncData
        };
      },
      render(h): Vue.VNode {
        return h(
          /**
           * If server template exist, then render this
           */
          serverTemplate || 'div',
          {
            props: {
              // @ts-ignore
              content: this.$data.content,
              // @ts-ignore
              countryCode: this.$data.countryCode,
              ...this.$data.asyncData
            }
          },
          [
            h('client-only', {}, [
              // @ts-ignore
              h(contentResource.renderComponent(this.$data.content, this.$data.asyncData))
            ])
          ]
        );
      }
    });
  }
}
