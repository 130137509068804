<template>
  <!-- eslint-disable-next-line vue-a11y/click-events-have-key-events -->
  <div v-bind="$attrs" @click="activeMenu = !activeMenu">
    <div
      class="full-page"
      :class="{
        'single-page': activeSinglePage,
        footer: footer,
        'full-white': fullWhite,
        'no-nav': hideNav,
        'white-label': isWhiteLabelPage
      }"
      :style="appBarHeight"
    >
      <app-bar
        v-if="!activeSinglePage && !useCustomHeader"
        class="header"
        style="box-shadow: none"
        :logo="QoalaLogo"
        right-component
      >
        <client-only>
          <change-language-app
            :content="content"
            location-component="home"
          ></change-language-app>
          <q-row v-if="!isLogin" justify="flex-end">
            <q-button
              v-if="isHomePage"
              :title="`${content.QoalaOnlineGeneral.loginButtonLabel}/${content.QoalaOnlineGeneral.registerButtonLabel}`"
              :style="[
                $route.path.indexOf('account') >= 0
                  ? { width: '50%' }
                  : { width: 'auto' },
              ]"
              style="
                width: auto;
                height: 26px;
                margin-left: 10px;
                padding: 4px;
                background: linear-gradient(to bottom, #ff9738, #ff8743);
              "
              text-variant="ui-small"
              @click="topbarAction('login')"
            >
              {{
                `${content.QoalaOnlineGeneral.loginButtonLabel}/${content.QoalaOnlineGeneral.registerButtonLabel}`
              }}
            </q-button>
          </q-row>
        </client-only>
        <q-row v-if="isProductPage">
          <q-spacer
            v-for="menu in menusProductPage"
            :key="menu.name"
            left="xxs"
          >
            <q-text
              elevation="flat"
              class="btnTopPolicyList"
              @click.native="topbarAction(menu.name)"
            >
              <q-row justify="space-around" align-items="center">
                <img
                  v-lazy="menu.icon"
                  src="https://img.qoala.app/qapp-assets/images/placeholder_all_size.png"
                  style="width: 14px"
                  :alt="'placeholder-' + menu.name"
                  :title="'placeholder-' + menu.name"
                />
                <q-spacer class="policySpace" horizontal="xxs"></q-spacer>
                <q-text
                  variant="ui-small"
                  class="policySpace"
                  style="color: #2862ac"
                >
                  {{ menu.label }}
                </q-text>
              </q-row>
            </q-text>
          </q-spacer>
        </q-row>
      </app-bar>

      <app-bar-navigation
        v-show="activeSinglePage && !useCustomHeader"
        ref="appBarNavigation"
        class="header"
        :background-color="backgroundColor"
        :logo="QoalaLogo"
        :title="titleStat"
        :is-white="isWhite"
        :color="titleColor"
        :right-component="rightComponent"
        :hide-navigation="hideSinglePageNavigation"
        @back="handleBack"
        @right="handleRightClick"
      ></app-bar-navigation>
      <general-auto-fill
        v-if="autoFill"
        :title="content.QoalaOnlineGeneral.autoFillLabel"
        @handle-auto-fill="handleAutoFill"
      ></general-auto-fill>
      <general-navigation
        v-if="!hideNav && !activeSinglePage && !isWhiteLabelPage"
        :active-menu="activeMenu"
        :content="content"
      ></general-navigation>
      <div class="content-container">
        <tool-loader
          v-if="onLoading && !hideLoader"
          :is-loading="onLoading"
        ></tool-loader>
        <tool-message></tool-message>
        <tool-confirmation
          v-show="isLogin"
          :content="content"
        ></tool-confirmation>
        <slot></slot>
        <q-spacer v-if="countryID && isProductList" vertical="md">
          <general-collaborate-footer
            :content="content.QoalaOnlineGeneral"
          ></general-collaborate-footer>
        </q-spacer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  AppBar,
  AppBarNavigation,
  Button as QButton,
  Spacer as QSpacer,
  Row as QRow,
  Text as QText,
} from '@qoala/ui';
import QoalaLogo from '@/assets/icons/Logo';
import ShareIcon from '@/assets/svg/ShareIcon.vue';
const backIcon = 'https://assets.qoala.app/images/icons/back-button.png';
const AddPolicyIcon = 'https://assets.qoala.app/icons/AddPolicy.svg';
const TransactionHistoryIcon =
  'https://assets.qoala.app/icons/TransactionHistory.svg';

export default {
  name: 'GeneralTemplate',
  components: {
    AppBar,
    AppBarNavigation,
    GeneralNavigation: () => import('@/components/general/GeneralNavigation'),
    ToolLoader: () => import('@/components/tool/ToolLoader'),
    QButton,
    QSpacer,
    GeneralAutoFill: () => import('@/components/general/GeneralAutoFill'),
    QRow,
    QText,
    ToolMessage: () => import('@/components/tool/ToolMessage.vue'),
    ToolConfirmation: () => import('@/components/tool/ToolConfirmation.vue'),
    GeneralCollaborateFooter: () =>
      import('@/components/general/GeneralCollaborateFooter'),
    ChangeLanguageApp: () =>
      import('@/components/general/ChangeLanguageApp.vue'),
  },
  props: {
    useCustomHeader: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    titleColor: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    content: {
      type: Object,
      default: () => ({}),
    },
    onLogin: {
      type: Boolean,
      default: false,
    },
    fullWhite: {
      type: Boolean,
      default: false,
    },
    activeSinglePage: {
      type: Boolean,
      default: false,
    },
    titleStat: {
      type: String,
      default: 'Qoala',
    },
    handleBack: {
      type: Function,
      default: () => {
        if (this && this.$router) {
          this.$router.back();
        }
        if (window.history.length > 2) {
          window.history.back();
        } else {
          if (this && this.$router)
            this.$router.push(this.localePath('index', this.$i18n.locale));
        }
      },
    },
    isWhite: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    hideNav: {
      type: Boolean,
      default: false,
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
    rightComponent: {
      type: Object,
      default: () => {},
    },
    footer: {
      type: Boolean,
      default: false,
    },
    hideLoader: {
      type: Boolean,
      default: false,
    },
    isPromoShared: {
      type: Boolean,
      default: false,
    },
    autoFill: {
      type: Boolean,
      default: false,
    },
    hideSinglePageNavigation: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    trackingEvent: {
      type: String,
      default: '',
      required: false,
    },
    isProductList: {
      type: Boolean,
      default: false,
    },
    isWhiteLabelPage: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      activeMenu: false,
      menuHide: backIcon,
      QoalaLogo,
      TransactionHistoryIcon,
      AddPolicyIcon,
      ShareIconImage: ShareIcon,
      singlePageHeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      countryCode: 'core/getCountryCode',
      onLoading: 'general/getLoadingStatus',
      userToken: 'user/getUserToken',
    }),
    menusProductPage() {
      return [
        {
          name: 'add',
          label: this.content.QoalaOnlineGeneral.addLabel,
          icon: AddPolicyIcon,
        },
        {
          name: 'transaction',
          label: this.content.QoalaOnlineGeneral.transactionLabel,
          icon: TransactionHistoryIcon,
        },
      ];
    },
    isProductPage() {
      const path = 'products';
      const activePath = this.$route.path;
      if (activePath.indexOf(path) >= 0) {
        return true;
      }
      return false;
    },
    isHomePage() {
      const path = 'index';
      const activePath = this.$route.path;
      const includedPath = ['/id', '/', '/my', '/vn', '/th', '/ph'];
      if (
        activePath.indexOf(path) >= 0 ||
        activePath.endsWith('-en') ||
        includedPath.includes(activePath)
      ) {
        return true;
      }
      return false;
    },
    isLogin() {
      return !this.$helper.isEmpty(this.userToken);
    },
    appBarHeight() {
      if (this.activeSinglePage && this.singlePageHeight > 0) {
        const padding = `padding: ${this.singlePageHeight}px 0 0 !important;`;
        return padding;
      }
      return '';
    },
    countryID() {
      return this.countryCode === 'ID';
    },
  },
  async mounted() {
    if (
      this.activeSinglePage &&
      this.$refs.appBarNavigation &&
      this.$refs.appBarNavigation.$el &&
      this.$refs.appBarNavigation.$el.scrollHeight > 0
    ) {
      this.singlePageHeight = this.$refs.appBarNavigation.$el.clientHeight;
    }
    // Init Recaptcha Generally
    try {
      await this.$recaptcha.init();
    } catch (e) {
      console.error('Failed to initiate Recaptcha', e);
    }
  },
  methods: {
    handleRightClick() {
      this.$emit('open-modal-shared');
    },
    topbarAction(action) {
      if (this.trackingEvent) {
        if (action === 'login' || action === 'sessions-register') {
          let category;
          if (action === 'login') category = 'Login';
          else category = 'Register';
          this.$analytics.pushEvent({
            event: `${this.trackingEvent}`,
            eventCategory: category,
            eventAction: this.trackingEvent,
            eventLabel: `${this.trackingEvent}-Click-${category}`,
          });
        }
      }
      switch (action) {
        case 'login':
          this.$router.push(this.localePath('account'));
          break;
        case 'add':
          this.$router.push(this.localePath('policy-add'));
          break;
        case 'transaction':
          this.$router.push(this.localePath('transactions'));
          break;
        default:
          this.$router.push(this.localePath('sessions-register'));
          break;
      }
    },
    handleAutoFill(data) {
      this.$emit('handle-auto-fill', data);
    },
  },
  beforeDestroy() {
    this.$recaptcha.destroy();
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 480px) {
  .header {
    max-width: 480px;
    left: calc(50vw - 240px) !important;
  }
}

.full-page {
  padding: 43px 0 55px;
  min-height: 100vh;
  position: relative;
  left: 0;
  overflow-x: hidden;
  transition: left 0.5s;
}
.full-page.logged-in {
  padding: 50px 0 56px;
  overflow-x: hidden;
}
.full-page.active {
  overflow-x: hidden;
  pointer-events: none;
}
.full-page.white-label {
  padding: 0px;
  // Hide header and footer on whitelabel page
  .header,
  .navigation-container {
    display: none;
  }
}
.single-page {
  overflow-x: auto;
  padding: 44px 0 0 !important;
  height: 100vh;
}
.content-container {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 auto;
  max-width: 480px;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
.footer {
  height: calc(100vh - 55px);
}
.btnTopPolicyList {
  width: 100%;
  padding: 5px;
  justify-content: center;
  height: 24px;
  cursor: pointer;
  background-color: #f4f8fe !important;
  border: solid 1px rgba(145, 194, 255, 0.25);
  border-radius: 4px;
}
</style>

<style lang="scss">
@media (min-width: 480px) {
  // Styling override for NewTray
  .eu-tray-container {
    left: 0;
    left: calc(50vw - 240px) !important;
    justify-content: flex-start !important;
  }

  .eu-tray-body {
    max-width: 480px !important;
  }

  .modal-container {
    max-width: 480px !important;
    left: calc(50vw - 240px) !important;
  }

  // Styling override for (old) Tray
  .tray-container {
    left: calc(50vw - 240px) !important;
    justify-content: flex-start !important;
  }

  .tray-content-container {
    max-width: 480px;
  }
}

@media (min-width: 480px) {
  .footer-action {
    max-width: 480px;
    left: calc(50vw - 240px);
  }
}
@media (max-width: 480px) {
  .footer-action {
    left: 0;
  }
  .policySpace {
    display: none;
  }
}
.footer-action {
  position: fixed;
  bottom: 0;
  right: 0;
}

.withActions {
  grid-template-columns: 20% auto 20% !important;
}
</style>
