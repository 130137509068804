import Vue from 'vue';
import { Context, NuxtAppOptions } from '@nuxt/types/app';
import { getContentResource, mapDataToContent } from '../ContentResource';
import { CreatePage } from '../ContentResource/ContentResource.type';

interface I18nLocale {
  code: string;
  iso: string;
}

interface ContextI18n {
  locales: I18nLocale[];
  locale: string;
}

export interface ContextWithLocale extends Context {
  app: NuxtAppOptions & {
    i18n: ContextI18n;
  };
}

export default class ContentResource {
  options: CreatePage;
  constructor(options: CreatePage) {
    this.options = options;
  }

  /**
   * Parse content from i18n plugin.
   *
   * @param queries List of Content Query
   * @param i18n i18n plugin context
   */
  private getContentFromI18n(queries: { [key: string]: string[] }, i18n: any) {
    let i18nContent = {};

    if (i18n && i18n.t) {
      Object.keys(queries).forEach(query => {
        const resource = i18n.t(query);
        if (resource && typeof resource !== 'string') {
          i18nContent = {
            ...i18nContent,
            [query]: resource
          };
        }
      });
    }

    return i18nContent;
  }

  async getContent(
    components: { [key: string]: any },
    locale: any,
    i18n: any
  ): Promise<ReturnType<typeof mapDataToContent> | null> {
    const { queries, lang, method } = this.options;

    const componentQuery: { [key: string]: string[] } = {};

    if (this.options.noContent) {
      return null;
    }

    Object.keys(components).forEach(componentKey => {
      if (components[componentKey].queries) {
        Object.keys(components[componentKey].queries).forEach(componentQueryKey => {
          componentQuery[componentQueryKey] = [
            ...((queries || [])[componentKey] || []),
            ...components[componentKey].queries[componentQueryKey]
          ];
        });
      }
    });

    Object.keys(componentQuery).forEach(componentQueryKey => {
      componentQuery[componentQueryKey] = [...new Set(componentQuery[componentQueryKey])];
    });

    const contentQuery = Object.assign(queries, componentQuery);
    const i18nContent = this.getContentFromI18n(contentQuery, i18n);
    try {
      // Check object is not empty
      if (Object.keys(i18nContent).length > 0) {
        return i18nContent;
      }
      const data = await getContentResource(contentQuery, lang || locale, method);
      if (data && data.length === 0) {
        throw new Error('Empty data');
      }
      const content = mapDataToContent(contentQuery, data);
      return content;
    } catch (err) {
      return i18nContent;
    }
  }

  renderComponent(content: { [key: string]: any }, asyncData: Function): Vue.Component {
    const { asyncFunc = () => null, ...options } = this.options;
    /**
     * I wish we could return only an array
     * Will wait till Vue 3.0 released to make this happen
     * temporary solution is to wrap it in div =(
     */
    return Vue.extend({
      render(h: Vue.CreateElement) {
        return h(
          'div',
          Object.keys(options.components).map(component => {
            const componentsAndQueries = [
              ...Object.keys((options.components[component] || {}).queries || {}),
              ...Object.keys(options.queries || {})
            ]
            const contentFilByQueries = {}
            Object.keys(content).forEach((itemContent) => {
              if (componentsAndQueries.includes(itemContent)) {
                contentFilByQueries[itemContent] = content[itemContent];
              }
            })
            return h(options.components[component], {
              props: {
                /**
                 * Only return the defined *ENTRY* for each component
                 * TODO: Only return specific key for each component
                 */
                content: contentFilByQueries,
                asyncData
              }
            });
          })
        );
      }
    });
  }
}
